import React, { useEffect, Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DotLoader } from 'react-spinners';
import Chatbot from './Footer/Chatbot';
import RefundPolicy from './TermsAndCondition/RefundPolicy';
import About from './About/About';
import Privacy from './Privacy/Privacy';
import AWSMain from './AWS/AWSMain';

// Lazy load the components
const CloudCourseMain = React.lazy(() => import('./components/CloudCourseMain'));
const LandingPage = React.lazy(() => import('./LandingPageComponents/LandingPage'));
const AllFooter = React.lazy(() => import('./Footer/AllFooter'));
const AllHeader = React.lazy(() => import('./Header/AllHeader'));
const StickyFooter = React.lazy(() => import('./Footer/StickyFooter'));
const BlogsPage = React.lazy(() => import('./Blogs/BlogsPage'));
const TermsAndCondition = React.lazy(() => import('./TermsAndCondition/TermsAndCondition'));
const ThankyouPage = React.lazy(() => import('./ThankyouComponent/ThankyouPage'));

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-N1CE0V9ZXD', {
        page_path: location.pathname,
      });
      window.gtag('config', 'AW-16687929732', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <div className="container">
      <Helmet>
        {/* Google Analytics (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1CE0V9ZXD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1CE0V9ZXD', {
              page_path: window.location.pathname,
            });
            gtag('config', 'AW-16687929732', {
              page_path: window.location.pathname,
            });
          `}
        </script>
        
        {/* Microsoft Clarity Script */}
        <script type="text/javascript">
          {`
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "o598xd72w1");
          `}
        </script>

         {/* Facebook Pixel Script */}
         <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '587545047035951'); 
            fbq('track', 'PageView');
          `}
        </script>
      </Helmet>

      {/* Render noscript with dangerouslySetInnerHTML */}
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <noscript>
              <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=587545047035951&ev=PageView&noscript=1" />
            </noscript>
          `,
        }}
      />

      <Suspense fallback={<div style={{margin: "20% auto 50% auto", display: "table"}}><DotLoader color="#8f128e" loading/></div>}>
        <AllHeader />
        <StickyFooter />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/devops-training-online" element={<CloudCourseMain />} />
          <Route path="/aws-training-online" element={<AWSMain />} />
          <Route path="/blog" element={<BlogsPage />} />
          <Route path="/terms" element={<TermsAndCondition />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/thankyou" element={<ThankyouPage />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <Chatbot />
        <AllFooter />
      </Suspense>
    </div>
  );
}

const AppWithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWithRouter;
